
import {fire, SignInBlock} from './firebase'

import { collection, getDocs, getDoc, addDoc, doc, onSnapshot, deleteDoc, updateDoc } from "firebase/firestore";


class GlobalState {
	constructor (f) {
		this.machine = {					// the state machine
			currentState: "Out",
			validStates: ["Out","Land", "Projects", "Active"],
			notify: []
		}
		this.user = null
		this.components = {}				// components that should render across multiple pages
		this.fire = f
		this.project = {
			current: null,
			notify: [],
			toAdd: null,
			type: null,
		}

		this.busy = {
			state: false,
			notify: []
		}

		this.task = {
			running: null,
			success: false,
			callback: null,
		}
	}

	// State Machine functions
	registerForStateChange(f) 	{this.machine.notify.push(f)}
	changeState(s) 				{this.machine.currentState = s;this.machine.notify.map((f)=>f(s))}
	currentState() 				{return this.machine.currentState}
	signIn() 					{this.changeState("Land")}
	signOut() 					{this.changeState("Out")}
	isSignedIn() 				{return (this.machine.currentState != "Out")}

	// User functions
	setUser(u)					{this.user = u;}
	getUser()					{return this.user}

	// ActiveProject	
	getProject()				{return this.project.current}
	getProjectType()			{return this.project.type}
	setProject(p,t)				{this.project.current = p; this.project.type = t; this.project.notify.map((f)=>f(p))}
	async refetchProject()		{const id = this.project.current.id;
	 							 this.project.current = await getDoc(doc(this.fire.db,"projects",id));
	 							}
	setProjectType(t)			{this.project.type = t}
	updateProject(p)			{this.project.current = p}
	clrProject()				{this.project.current = null; this.project.notify.map((f)=>f(null))}
	setAddProject(n)			{this.project.toAdd = n; console.log("Set up to add",n)}
	addProject()			    {if (!this.project.toAdd) return null; const ta = this.project.toAdd; this.project.toAdd = null; return ta}
	registerForProjChange(f)	{this.project.notify.push(f)}

	// Jobs
	getJobs()				{return this.jobs}

	// Components
	addComponent(n,c)			{this.components[n] = c;}
	getComponent(n)				{return this.components[n]}
	getComponents()				{return this.components}

	// Firebase
	setFire(f)					{this.fire = f}
	getFire()					{return this.fire}

	// Busy
	setBusy()					{this.busy.state = true;  this.busy.notify.map((f)=>f(true ))}
	clrBusy()					{this.busy.state = false; this.busy.notify.map((f)=>f(false))}
	registerForBusy(f)			{this.busy.notify.push(f)}

	//Tasks
	setTask(t,cb)				{this.task.running = t; this.task.callback = cb}
	getTask()					{return this.task.running}
	clrTask()					{this.task.running = null; this.task.callback = null; this.task.success = false}
	setTaskResult(b)			{this.task.success = b; console.log( "Task result set"); this.task.callback()}
	getTaskResult()				{return this.task.success}
}

// State: Load defaults and attach Global Components and Firebase
//const js = new JobState(fire)
const js = null
const state = new GlobalState(fire);
state.addComponent("SignInOut",<SignInBlock state={state}/>)
//state.setFire(fire)

export {state}